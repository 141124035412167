import React,{useState} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import classes from './Music.module.css'
import Swal from "sweetalert2";
import { codeData } from './MusicCode';
import axios from 'axios';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:"10px"
};
const btnStlyle={
    backgroundColor: '#eb0909',
    borderRadius: '28px',
    transition: '1s',
    border: '1px solid rgb(237, 17, 17)',
    display: 'inline-block',
    cursor: 'pointer',
    color: '#ffffff',
    marginTop: '10px',
    fontFamily: 'Arial',
    fontSize: '15px',
    padding: '5px 40px',
    textDecoration: 'none',
    textShadow: '0px 1px 0px #2f6627',
    marginBottom:'10px'
}



function BuyModal({ID}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [codeNo,setCodeNo] = useState('');
    const id = [1, 2, 3, 4, 5, 6];
    const [requestCount, setRequestCount] = useState(0);
    const parameters = [1, 2, 3, 4, 5, 6];
    const [token, setToken] = useState(null);

  
    
    const submitHandler = async (e) => {
      e.preventDefault();
      console.log(codeNo);
    
      try {
        const tokenResponse = await axios.post(`${process.env.REACT_APP_DOMAIN}api/invoke-token`, { code: codeNo });
    
        if (tokenResponse.data.status === "success") {
          const token = tokenResponse.data.data.token;
          const parameters = [8];
        
          for (let i = 0; i < parameters.length; i++) {
            const currentId = parameters[i];
        
            try {
              const downloadResponse = await axios.get(`${process.env.REACT_APP_DOMAIN}api/download-music?music_id=${currentId}`, {
                headers: { authorization: token },
              });
        
              if (downloadResponse.data.status === "success") {
                const videoUrl = `${process.env.REACT_APP_DOMAIN}${downloadResponse.data.data.file_path}`;
                const link = document.createElement("a");
                link.href = videoUrl;
                link.setAttribute("download", `music-${currentId}.mp4`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
        
                // Add a delay of 5 seconds between each request
                await new Promise(resolve => setTimeout(resolve, 5000));
              } else {
                throw new Error(`Download failed for id ${currentId}: ${downloadResponse.data.message}`);
              }
            } catch (downloadError) {
              console.error(`Error downloading music for id ${currentId}: ${downloadError.message}`);
            }
          }
        } else {
          throw new Error(`Token request failed: ${tokenResponse.data.message}`);
        }
      } catch (tokenError) {
        console.error(`Error obtaining token: ${tokenError.message}`);
      }
    };
    

  return (
    <div>
      <Button onClick={handleOpen} style={btnStlyle}>Buy</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
         <input type="text" placeholder="Enter Code" onChange={(e)=>{setCodeNo(e.target.value)}} className={classes.buyInput} ></input> 
         <button className={classes.buyBtn} onClick={submitHandler}>Submit</button>
         <p style={{textAlign: "center"}}>OR</p>
         <button
        
         className= {`${classes["loginBtn"]} ${classes["loginBtn--facebook"]}`}>
          <a style={{
              textDecoration: "none",
              color: "white"
          }} href="https://www.facebook.com/profile.php?id=100087630447354&mibextid=ZbWKwL" target="_blank">
          GO TO FACEBOOK
          </a>

</button>
         <div className={classes.contact}>

       
         </div>
   
        </Box>
      </Modal>
    </div>
  )
}

export default BuyModal